export const audioFiles = [
    {
        name: 'Late Again',
        url: 'https://media.githubusercontent.com/media/brocro/audio-storage/main/audio/LateAgain.mp3'
    },
    {
        name: 'Under My Nails',
        url: 'https://media.githubusercontent.com/media/brocro/audio-storage/main/audio/UnderMyNails.mp3'
    },
    {
        name: 'Heartfelt',
        url: 'https://media.githubusercontent.com/media/brocro/audio-storage/main/audio/Heartfelt.mp3'
    },
    {
        name: 'Melancholiian',
        url: 'https://media.githubusercontent.com/media/brocro/audio-storage/main/audio/Melancholiian.mp3'
    },
    {
        name: 'Hard Day At The Office',
        url: 'https://media.githubusercontent.com/media/brocro/audio-storage/main/audio/HardDayAtTheOffice.mp3'
    },
];